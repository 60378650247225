<template>
  <FormTemplate v-if="form" :form="form">
    <b-row>
      <b-col>
        <div class="form-group">
            <label class="label-for-xl">Name</label>
            <b-form-input
                type="text" class="form-control form-control-xl"
                placeholder="enter name"
                v-model="form.data.name"
                :state="form.states.name"
            />
            <b-form-invalid-feedback v-if="form.errors">{{ form.errors.name }}</b-form-invalid-feedback>
        </div>
      </b-col>
      <b-col>
        <div class="form-group">
            <label class="label-for-xl">Placeholder</label>
            <b-form-input
                type="text" class="form-control form-control-xl"
                placeholder="enter placeholder"
                v-model="form.data.placeholder"
                :state="form.states.placeholder"
            />
            <b-form-invalid-feedback v-if="form.errors">{{ form.errors.placeholder }}</b-form-invalid-feedback>
        </div>
      </b-col>
    </b-row>

    <b-row>
      <b-col>
        <div class="form-group">
            <label class="label-for-xl">Default Value</label>
            <b-form-input
                type="text" class="form-control form-control-xl"
                placeholder="enter amount"
                v-model="form.data.amount"
                :state="form.states.amount"
            />
            <b-form-invalid-feedback v-if="form.errors">{{ form.errors.amount }}</b-form-invalid-feedback>
        </div>
      </b-col>
      <b-col>
        <div class="form-group">
            <label class="label-for-xl">Validity</label>
            <b-form-select v-model="form.data.validity" :state="form.states.validity" :options="validityOptions" class="mb-3"></b-form-select>
            <b-form-invalid-feedback v-if="form.errors">{{ form.errors.validity }}</b-form-invalid-feedback>
        </div>
      </b-col>
      <b-col>
        <div class="form-group">
            <label class="label-for-xl">Upgradable</label>
            <b-form-select v-model="form.data.upgradable" :state="form.states.upgradable" class="mb-3">
              <b-form-select-option :value="true">Yes</b-form-select-option>
              <b-form-select-option :value="false">No</b-form-select-option>
            </b-form-select>
            <b-form-invalid-feedback v-if="form.errors">{{ form.errors.upgradable }}</b-form-invalid-feedback>
        </div>
      </b-col>
      <b-col>
        <div class="form-group">
            <label class="label-for-xl">Downgradable</label>
            <b-form-select v-model="form.data.downgradable" :state="form.states.downgradable" class="mb-3">
              <b-form-select-option :value="true">Yes</b-form-select-option>
              <b-form-select-option :value="false">No</b-form-select-option>
            </b-form-select>
            <b-form-invalid-feedback v-if="form.errors">{{ form.errors.downgradable }}</b-form-invalid-feedback>
        </div>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="4">
        <div class="form-group">
            <label class="label-for-xl">Status</label>
            <b-form-select v-model="form.data.status" :state="form.states.status" :options="statusesOptions" class="mb-3"></b-form-select>
            <b-form-invalid-feedback v-if="form.errors">{{ form.errors.status }}</b-form-invalid-feedback>
        </div>
      </b-col>
    </b-row>
  </FormTemplate>
</template>

<script>
import {Form} from '@/common/utils/parnekt';
export default {
  props: ['product'],
    components: {
      FormTemplate: () => import('@/common/components/Form/Form'),
    },
    data(){
      return {
        form: null,
        validityOptions: [
          {
            value: "monthly",
            text: "Monthly",
          },
          {
            value: "yearly",
            text: "Yearly",
          },
          {
            value: "lifetime",
            text: "Lifetime",
          }
        ],
        statusesOptions: [
          {
            value: "active",
            text: "Active",
          },
          {
            value: "disabled",
            text: "Disabled",
          }
        ]
      }
    },
    methods: {

    },
    mounted(){
      let product = this.product || {};
      this.form = new Form({
        name: product.name || "",
        placeholder: product.placeholder || "",
        amount: product.amount || "",
        upgradable: product.upgradable || false,
        downgradable: product.downgradable || false,
        validity: product.validity || "monthly",
        status: product.status || "disabled",
      },{
        onSubmit: (form) => {
          if(!this.product){
            return this.$api.post('product',{data: form.get()})
          }
          else{
            return this.$api.put(`product/${this.product.id}`,{data: form.get()});
          }
        },
        onSuccess: (form, {data}) => {
          if(!this.product){
            this.$router.push({name: "products.product.edit", params: {productId: data.id}})
          }
        }
      });
    },
};
</script>
